/** @jsx jsx */
import ExhibitorTemplate from '@bottlebooks/gatsby-theme-event/src/templates/ExhibitorTemplate';
import { graphql } from 'gatsby';

export default ExhibitorTemplate;

export const pageQuery = graphql`
  query custom_ExhibitorTemplate(
    $id: String!
    $eventId: ID!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        registeredBrands(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            ...bb_ExhibitorPage_RegisteredBrands
            ...custom_PresentedBrands
          }
        }

        registrations(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            ...bb_Registration
          }
        }
        exhibitor(exhibitorId: $exhibitorId) {
          ...bb_ExhibitorPage
          products {
            nodes {
              ...custom_ProductRegion
              productId
              description
              shopImage: bottleImage {
                fixed(width: 80, height: 80, crop: PAD, background: "#FFF0") {
                  src
                }
              }
              fullName: name(includeProducer: AUTO)
            }
          }
        }
      }
    }

    exhibitor(id: { eq: $id }) {
      ...ExhibitorTemplate_Exhibitor
    }
    previous: exhibitor(id: { eq: $previousId }) {
      ...ChangeExhibitorLink
    }
    next: exhibitor(id: { eq: $nextId }) {
      ...ChangeExhibitorLink
    }
    ...ExhibitorTemplate_Query
  }

  fragment ExhibitorTemplate_Exhibitor on Exhibitor {
    ...ExhibitorPage
    event {
      ...ExhibitorPage_Event
    }
  }

  fragment ExhibitorTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
